import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBoxArchive as fasFaBoxArchive,
  faArrowLeft as fasFaArrowLeft,
  faArrowRight as fasFaArrowRight,
  faArrowLeftLong as fasFaArrowLeftLong,
  faArrowRightLong as fasFaArrowRightLong,
  faArrowUpRight as fasFaArrowUpRight,
  faArrowUpRightFromSquare as fasFaArrowUpRightFromSquare,
  faArrowsRotate as fasFaArrowsRotate,
  faVolume as fasFaVolume,
  faVolumeXmark as fasFaVolumeXmark,
  faBackpack as fasFaBackpack,
  faBagShopping as fasFaBagShopping,
  faBars as fasFaBars,
  faBell as fasFaBell,
  faBook as fasFaBook,
  faBooks as fasFaBooks,
  faBrowser as fasFaBrowser,
  faCalendar as fasFaCalendar,
  faCalendarRange as fasFaCalendarRange,
  faCalendarCirclePlus as fasFaCalendarCirclePlus,
  faCalendarPlus as fasFaCalendarPlus,
  faCalendarDay as fasFaCalendarDay,
  faCamera as fasFaCamera,
  faCaretUp as fasFaCaretUp,
  faCaretDown as fasFaCaretDown,
  faComments as fasFaComments,
  faCheck as fasFaCheck,
  faCheckCircle as fasFaCheckCircle,
  faChevronDown as fasFaChevronDown,
  faChevronLeft as fasFaChevronLeft,
  faChevronRight as fasFaChevronRight,
  faChevronUp as fasFaChevronUp,
  faClock as fasFaClock,
  faCog as fasFaCog,
  faRectangleVerticalHistory as fasFaRectangleVerticalHistory,
  faCookie as fasFaCookie,
  faCopy as fasFaCopy,
  faCopyright as fasFaCopyright,
  faDownload as fasFaDownload,
  faEar as fasFaEar,
  faEarthEurope as fasFaEarthEurope,
  faEllipsis as fasFaEllipsis,
  faEllipsisVertical as fasFaEllipsisVertical,
  faEnvelope as fasFaEnvelope,
  faEye as fasFaEye,
  faEyeSlash as fasFaEyeSlash,
  faFaceSmile as fasFaFaceSmile,
  faFileCheck as fasFaFileCheck,
  faFloppyDisk as fasFaFloppyDisk,
  faGingerbreadMan as fasFaGingerbreadMan,
  faGlasses as fasFaGlasses,
  faGrid as fasFaGrid,
  faGrid2 as fasFaGrid2,
  faHeadphones as fasFaHeadphones,
  faHeart as fasFaHeart,
  faHexagon as fasFaHexagon,
  faHouse as fasFaHouse,
  faHouseBlank as fasFaHouseBlank,
  faInfo as fasFaInfo,
  faInfoCircle as fasFaInfoCircle,
  faLampDesk as fasFaLampDesk,
  faLayerGroup as fasFaLayerGroup,
  faLeaf as fasFaLeaf,
  faLifeRing as fasFaLifeRing,
  faLink as fasFaLink,
  faList as fasFaList,
  faLocationPin as fasFaLocationPin,
  faLock as fasFaLock,
  faArrowRightFromBracket as fasFaArrowRightFromBracket,
  faMagnifyingGlass as fasFaMagnifyingGlass,
  faPhotoFilm as fasFaPhotoFilm,
  faMinus as fasFaMinus,
  faMusic as fasFaMusic,
  faNewspaper as fasFaNewspaper,
  faPause as fasFaPause,
  faPencil as fasFaPencil,
  faPencilSquare as fasFaPencilSquare,
  faPersonChalkboard as fasFaPersonChalkboard,
  faPlay as fasFaPlay,
  faPlus as fasFaPlus,
  faPrint as fasFaPrint,
  faQuestion as fasFaQuestion,
  faQuestionCircle as fasFaQuestionCircle,
  faReceipt as fasFaReceipt,
  faSeedling as fasFaSeedling,
  faShare as fasFaShare,
  faShieldCheck as fasFaShieldCheck,
  faSliders as fasFaSliders,
  faSlidersUp as fasFaSlidersUp,
  faStars as fasFaStars,
  faText as fasFaText,
  faThoughtBubble as fasFaThoughtBubble,
  faTrash as fasFaTrash,
  faTrashXmark as fasFaTrashXmark,
  faTriangleExclamation as fasFaTriangleExclamation,
  faUniversalAccess as fasFaUniversalAccess,
  faUser as fasFaUser,
  faUsers as fasFaUsers,
  faVideo as fasFaVideo,
  faWaveform as fasFaWaveform,
  faXmark as fasFaXmark,
  faXmarkCircle as fasFaXmarkCircle,
  faFaceSunglasses as fasFaFaceSunglasses,
  faWavePulse as fasFaWavePulse,
  faChildReaching as fasFaChildReaching,
  faMaximize as fasFaMaximize,
  faMinimize as fasFaMinimize,
  faExpand as fasFaExpand,
  faCompress as fasFaCompress
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  fasFaBoxArchive,
  fasFaArrowLeft,
  fasFaArrowRight,
  fasFaArrowLeftLong,
  fasFaArrowRightLong,
  fasFaArrowUpRight,
  fasFaArrowUpRightFromSquare,
  fasFaArrowsRotate,
  fasFaVolume,
  fasFaVolumeXmark,
  fasFaBackpack,
  fasFaBagShopping,
  fasFaBars,
  fasFaBell,
  fasFaBook,
  fasFaBooks,
  fasFaBrowser,
  fasFaCalendar,
  fasFaCalendarRange,
  fasFaCalendarCirclePlus,
  fasFaCalendarPlus,
  fasFaCalendarDay,
  fasFaCamera,
  fasFaCaretUp,
  fasFaCaretDown,
  fasFaComments,
  fasFaCheck,
  fasFaCheckCircle,
  fasFaChevronDown,
  fasFaChevronLeft,
  fasFaChevronRight,
  fasFaChevronUp,
  fasFaClock,
  fasFaCog,
  fasFaRectangleVerticalHistory,
  fasFaCookie,
  fasFaCopy,
  fasFaCopyright,
  fasFaDownload,
  fasFaEar,
  fasFaEarthEurope,
  fasFaEllipsis,
  fasFaEllipsisVertical,
  fasFaEnvelope,
  fasFaEye,
  fasFaEyeSlash,
  fasFaFaceSmile,
  fasFaFileCheck,
  fasFaFloppyDisk,
  fasFaGingerbreadMan,
  fasFaGlasses,
  fasFaGrid,
  fasFaGrid2,
  fasFaHeadphones,
  fasFaHeart,
  fasFaHexagon,
  fasFaHouse,
  fasFaHouseBlank,
  fasFaInfo,
  fasFaInfoCircle,
  fasFaLampDesk,
  fasFaLayerGroup,
  fasFaLeaf,
  fasFaLifeRing,
  fasFaLink,
  fasFaList,
  fasFaLocationPin,
  fasFaLock,
  fasFaArrowRightFromBracket,
  fasFaMagnifyingGlass,
  fasFaPhotoFilm,
  fasFaMinus,
  fasFaMusic,
  fasFaNewspaper,
  fasFaPause,
  fasFaPencil,
  fasFaPencilSquare,
  fasFaPersonChalkboard,
  fasFaPlay,
  fasFaPlus,
  fasFaPrint,
  fasFaQuestion,
  fasFaQuestionCircle,
  fasFaReceipt,
  fasFaSeedling,
  fasFaShare,
  fasFaShieldCheck,
  fasFaSliders,
  fasFaSlidersUp,
  fasFaStars,
  fasFaText,
  fasFaThoughtBubble,
  fasFaTrash,
  fasFaTrashXmark,
  fasFaTriangleExclamation,
  fasFaUniversalAccess,
  fasFaUser,
  fasFaUsers,
  fasFaVideo,
  fasFaWaveform,
  fasFaXmark,
  fasFaXmarkCircle,
  fasFaFaceSunglasses,
  fasFaWavePulse,
  fasFaChildReaching,
  fasFaMaximize,
  fasFaMinimize,
  fasFaExpand,
  fasFaCompress
)
