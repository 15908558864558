<script setup lang="ts">
  import {
    ref,
    computed,
    watch,
    nextTick,
    onMounted,
    onBeforeUnmount
  } from 'vue'
  import { uid } from './utils'

  const props = withDefaults(
    defineProps<{
      title?: string
      direction?: string
      autoposition?: boolean
    }>(),
    {
      title: '',
      direction: 'down right',
      autoposition: true
    }
  )

  const hidden = ref(true)
  const opened = ref(false)
  const popover = ref()
  const content = ref()
  const toggle = ref()
  const button = ref()

  const uuid = computed(() => uid())
  const label = computed(
    () =>
      `${hidden.value ? 'Åpne' : 'Lukk'} valgmeny ${
        props.title ? `for ${props.title}` : ''
      }`
  )
  const transform = computed(() =>
    props.direction?.includes('down') ? 'translateY(-5%)' : 'translateY(5%)'
  )

  function onToggle(event: Event) {
    event.stopPropagation()
    event.preventDefault()
    hidden.value = !hidden.value
    if (!opened.value) {
      opened.value = true
      window.addEventListener('resize', onReposition)
      window.addEventListener('scroll', onReposition)
      document.addEventListener('click', onClick)
    }
  }

  function onReposition() {
    if (hidden.value) return
    const contentBox = content.value.getBoundingClientRect()
    const popoverBox = popover.value.getBoundingClientRect()
    const margin = 10
    const [upDown, leftRight] = props.direction.split(' ')
    const upwards = `${-(contentBox.height + margin)}px`
    const downwards = `${popoverBox.height + margin}px`
    const leftwards = `${-Math.round(contentBox.width - popoverBox.width)}px`
    let [fitsUp, fitsDown, fitsLeft, fitsRight] = Array(4).fill(true)
    fitsLeft = popoverBox.left - contentBox.width > 0
    fitsRight = popoverBox.left + contentBox.width < window.innerWidth

    if (props.autoposition) {
      fitsUp = popoverBox.top - contentBox.height > 0
      fitsDown = popoverBox.bottom + contentBox.height < window.innerHeight
    }
    if (upDown === 'up') content.value.style.top = fitsUp ? upwards : downwards
    if (upDown === 'down')
      content.value.style.top = fitsDown ? downwards : upwards
    if (leftRight === 'left')
      content.value.style.left = fitsLeft ? leftwards : '0px'
    if (leftRight === 'right')
      content.value.style.left = fitsRight ? '0px' : leftwards
    if (!fitsUp && !fitsDown) content.value.style.top = downwards // Overflow downwards
    if (!fitsLeft && !fitsRight)
      content.value.style.left = `calc(-${popoverBox.left}px + 1em)` // Full width
  }

  function onFocusOut({ relatedTarget }: FocusEvent) {
    if (hidden.value) return
    const focusTarget = (relatedTarget || document.body) as HTMLElement
    if (isOutside(focusTarget)) hidden.value = true
  }

  function onClick({ target }: MouseEvent) {
    if (hidden.value) return
    const clickTarget = target as HTMLElement
    if (isOutside(clickTarget)) hidden.value = true
  }

  function onEscape() {
    hidden.value = true
    button.value.focus()
  }

  function isOutside(el: HTMLElement) {
    for (; el !== document.body; el = el.parentElement as HTMLElement) {
      if (
        el === popover.value ||
        (el.classList.contains('ks-dialog') && !el.contains(popover.value))
      ) {
        return false
      }
    }
    return true
  }

  function setAttrs() {
    button.value.setAttribute('aria-label', label.value)
    button.value.setAttribute('aria-expanded', hidden.value ? 'false' : 'true')
  }

  onMounted(async () => {
    await nextTick() // Wait for toggle button slot to exist
    const toggleButton = toggle.value?.querySelector('button')
    if (!toggleButton) return // No slotted toggle button
    button.value = toggleButton
    button.value.classList.add('ks-popover-toggle')
    button.value.setAttribute('aria-controls', `ks-popover-${uuid.value}`)
    button.value.addEventListener('click', onToggle)
    button.value.addEventListener('focusout', onFocusOut)
    setAttrs()
  })

  onBeforeUnmount(() => {
    if (opened.value) {
      window.removeEventListener('resize', onReposition)
      window.removeEventListener('scroll', onReposition)
      window.removeEventListener('click', onClick)
    }
    if (button.value) {
      button.value.removeEventListener('click', onToggle)
      button.value.removeEventListener('focusout', onFocusOut)
    }
  })

  watch(
    () => props.title,
    () => setAttrs()
  )

  watch(
    () => hidden.value,
    () => {
      setAttrs()
      nextTick(onReposition)
    }
  )

  defineExpose({ el: popover })
</script>

<template>
  <div
    ref="popover"
    class="ks-popover"
    @keyup.esc.prevent="onEscape"
    @click.stop
  >
    <div ref="toggle">
      <slot name="toggle" />
    </div>
    <div
      :id="`ks-popover-${uuid}`"
      class="ks-popover-content"
      ref="content"
      :tabindex="-1"
      :hidden="hidden"
      @focusout="onFocusOut"
    >
      <slot
        v-if="opened"
        name="content"
      />
    </div>
  </div>
</template>

<style scoped>
  .ks-popover {
    position: relative;
    display: inline-block;
  }

  .ks-popover-content {
    position: absolute;
    min-width: min-content;
    max-width: calc(100vw - 2rem);
    padding: 0.5rem;
    overflow-y: auto;
    overscroll-behavior: contain;
    background: var(--ks-surface);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: calc(12px * var(--ks-roundness));
    font-size: 1rem;
    transition: 0.2s;
    transform: translate(0);
    z-index: 50;
  }

  .ks-popover-content[hidden] {
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: v-bind(transform);
    min-width: 0;
  }
</style>
