import { uid } from './utils'
import { DirectiveBinding } from 'vue'

type Direction = 'up' | 'down' | 'left' | 'right'

function hideTooltip(event: Event) {
  event.preventDefault()
  const target = event.target as HTMLElement
  const tooltip = target.nextElementSibling
  tooltip?.setAttribute('hidden', 'true')
}

function showTooltip(event: Event) {
  event.preventDefault()
  const target = event.target as HTMLElement
  const tooltip = target.nextElementSibling
  tooltip?.removeAttribute('hidden')
}

function onKeyDown(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    hideTooltip(event)
  }
}

const vKsTooltip = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const direction = (Object.keys(binding.modifiers)[0] || 'down') as Direction
    const id = `ks-tooltip--${uid()}`
    const wrapper = document.createElement('div')
    const tooltip = document.createElement('span')

    wrapper.classList.add('ks-tooltip-wrapper')
    tooltip.classList.add('ks-tooltip', `ks-tooltip-${direction}`)
    tooltip.setAttribute('id', id)
    tooltip.setAttribute('role', 'tooltip')
    tooltip.setAttribute('hidden', 'true')
    tooltip.setAttribute('aria-hidden', 'true')
    tooltip.innerText = binding.value || '...'
    el.setAttribute('aria-labelledby', id)

    el.parentNode?.insertBefore(wrapper, el)
    wrapper.appendChild(el)
    wrapper.appendChild(tooltip)

    el.addEventListener('keydown', onKeyDown)
    el.addEventListener('click', hideTooltip)
    el.addEventListener('mouseenter', showTooltip)
    el.addEventListener('mouseleave', hideTooltip)
    el.addEventListener('focusin', showTooltip)
    el.addEventListener('focusout', hideTooltip)
  },

  beforeUnmount(el: HTMLElement) {
    el.removeEventListener('keydown', onKeyDown)
    el.removeEventListener('click', hideTooltip)
    el.removeEventListener('mouseenter', showTooltip)
    el.removeEventListener('mouseleave', hideTooltip)
    el.removeEventListener('focusin', showTooltip)
    el.removeEventListener('focusout', hideTooltip)
  }
}

export default vKsTooltip
