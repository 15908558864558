import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBoxArchive as falFaBoxArchive,
  faArrowLeft as falFaArrowLeft,
  faArrowRight as falFaArrowRight,
  faArrowLeftLong as falFaArrowLeftLong,
  faArrowRightLong as falFaArrowRightLong,
  faArrowUpRight as falFaArrowUpRight,
  faArrowUpRightFromSquare as falFaArrowUpRightFromSquare,
  faArrowsRotate as falFaArrowsRotate,
  faVolume as falFaVolume,
  faVolumeXmark as falFaVolumeXmark,
  faBackpack as falFaBackpack,
  faBagShopping as falFaBagShopping,
  faBars as falFaBars,
  faBell as falFaBell,
  faBook as falFaBook,
  faBooks as falFaBooks,
  faBrowser as falFaBrowser,
  faCalendar as falFaCalendar,
  faCalendarRange as falFaCalendarRange,
  faCalendarCirclePlus as falFaCalendarCirclePlus,
  faCalendarPlus as falFaCalendarPlus,
  faCalendarDay as falFaCalendarDay,
  faCamera as falFaCamera,
  faCaretUp as falFaCaretUp,
  faCaretDown as falFaCaretDown,
  faComments as falFaComments,
  faCheck as falFaCheck,
  faCheckCircle as falFaCheckCircle,
  faChevronDown as falFaChevronDown,
  faChevronLeft as falFaChevronLeft,
  faChevronRight as falFaChevronRight,
  faChevronUp as falFaChevronUp,
  faClock as falFaClock,
  faCog as falFaCog,
  faRectangleVerticalHistory as falFaRectangleVerticalHistory,
  faCookie as falFaCookie,
  faCopy as falFaCopy,
  faCopyright as falFaCopyright,
  faDownload as falFaDownload,
  faEar as falFaEar,
  faEarthEurope as falFaEarthEurope,
  faEllipsis as falFaEllipsis,
  faEllipsisVertical as falFaEllipsisVertical,
  faEnvelope as falFaEnvelope,
  faEye as falFaEye,
  faEyeSlash as falFaEyeSlash,
  faFaceSmile as falFaFaceSmile,
  faFileCheck as falFaFileCheck,
  faFloppyDisk as falFaFloppyDisk,
  faGingerbreadMan as falFaGingerbreadMan,
  faGlasses as falFaGlasses,
  faGrid as falFaGrid,
  faGrid2 as falFaGrid2,
  faHeadphones as falFaHeadphones,
  faHeart as falFaHeart,
  faHexagon as falFaHexagon,
  faHouse as falFaHouse,
  faHouseBlank as falFaHouseBlank,
  faInfo as falFaInfo,
  faInfoCircle as falFaInfoCircle,
  faLampDesk as falFaLampDesk,
  faLayerGroup as falFaLayerGroup,
  faLeaf as falFaLeaf,
  faLifeRing as falFaLifeRing,
  faLink as falFaLink,
  faList as falFaList,
  faLocationPin as falFaLocationPin,
  faLock as falFaLock,
  faArrowRightFromBracket as falFaArrowRightFromBracket,
  faMagnifyingGlass as falFaMagnifyingGlass,
  faPhotoFilm as falFaPhotoFilm,
  faMinus as falFaMinus,
  faMusic as falFaMusic,
  faNewspaper as falFaNewspaper,
  faPause as falFaPause,
  faPencil as falFaPencil,
  faPencilSquare as falFaPencilSquare,
  faPersonChalkboard as falFaPersonChalkboard,
  faPlay as falFaPlay,
  faPlus as falFaPlus,
  faPrint as falFaPrint,
  faQuestion as falFaQuestion,
  faQuestionCircle as falFaQuestionCircle,
  faReceipt as falFaReceipt,
  faSeedling as falFaSeedling,
  faShare as falFaShare,
  faShieldCheck as falFaShieldCheck,
  faSliders as falFaSliders,
  faSlidersUp as falFaSlidersUp,
  faStars as falFaStars,
  faText as falFaText,
  faThoughtBubble as falFaThoughtBubble,
  faTrash as falFaTrash,
  faTrashXmark as falFaTrashXmark,
  faTriangleExclamation as falFaTriangleExclamation,
  faUniversalAccess as falFaUniversalAccess,
  faUser as falFaUser,
  faUsers as falFaUsers,
  faVideo as falFaVideo,
  faWaveform as falFaWaveform,
  faXmark as falFaXmark,
  faXmarkCircle as falFaXmarkCircle,
  faFaceSunglasses as falFaFaceSunglasses,
  faWavePulse as falFaWavePulse,
  faChildReaching as falFaChildReaching,
  faMaximize as falFaMaximize,
  faMinimize as falFaMinimize,
  faExpand as falFaExpand,
  faCompress as falFaCompress
} from '@fortawesome/pro-light-svg-icons'

library.add(
  falFaBoxArchive,
  falFaArrowLeft,
  falFaArrowRight,
  falFaArrowLeftLong,
  falFaArrowRightLong,
  falFaArrowUpRight,
  falFaArrowUpRightFromSquare,
  falFaArrowsRotate,
  falFaVolume,
  falFaVolumeXmark,
  falFaBackpack,
  falFaBagShopping,
  falFaBars,
  falFaBell,
  falFaBook,
  falFaBooks,
  falFaBrowser,
  falFaCalendar,
  falFaCalendarRange,
  falFaCalendarCirclePlus,
  falFaCalendarPlus,
  falFaCalendarDay,
  falFaCamera,
  falFaCaretUp,
  falFaCaretDown,
  falFaComments,
  falFaCheck,
  falFaCheckCircle,
  falFaChevronDown,
  falFaChevronLeft,
  falFaChevronRight,
  falFaChevronUp,
  falFaClock,
  falFaCog,
  falFaRectangleVerticalHistory,
  falFaCookie,
  falFaCopy,
  falFaCopyright,
  falFaDownload,
  falFaEar,
  falFaEarthEurope,
  falFaEllipsis,
  falFaEllipsisVertical,
  falFaEnvelope,
  falFaEye,
  falFaEyeSlash,
  falFaFaceSmile,
  falFaFileCheck,
  falFaFloppyDisk,
  falFaGingerbreadMan,
  falFaGlasses,
  falFaGrid,
  falFaGrid2,
  falFaHeadphones,
  falFaHeart,
  falFaHexagon,
  falFaHouse,
  falFaHouseBlank,
  falFaInfo,
  falFaInfoCircle,
  falFaLampDesk,
  falFaLayerGroup,
  falFaLeaf,
  falFaLifeRing,
  falFaLink,
  falFaList,
  falFaLocationPin,
  falFaLock,
  falFaArrowRightFromBracket,
  falFaMagnifyingGlass,
  falFaPhotoFilm,
  falFaMinus,
  falFaMusic,
  falFaNewspaper,
  falFaPause,
  falFaPencil,
  falFaPencilSquare,
  falFaPersonChalkboard,
  falFaPlay,
  falFaPlus,
  falFaPrint,
  falFaQuestion,
  falFaQuestionCircle,
  falFaReceipt,
  falFaSeedling,
  falFaShare,
  falFaShieldCheck,
  falFaSliders,
  falFaSlidersUp,
  falFaStars,
  falFaText,
  falFaThoughtBubble,
  falFaTrash,
  falFaTrashXmark,
  falFaTriangleExclamation,
  falFaUniversalAccess,
  falFaUser,
  falFaUsers,
  falFaVideo,
  falFaWaveform,
  falFaXmark,
  falFaXmarkCircle,
  falFaFaceSunglasses,
  falFaWavePulse,
  falFaChildReaching,
  falFaMaximize,
  falFaMinimize,
  falFaExpand,
  falFaCompress
)
