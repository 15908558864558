<script setup lang="ts">
  import { computed, reactive, watch } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsProgress } from '../../lib/js'

  const themeColor = computed(() =>
    window.getComputedStyle(document.body).getPropertyValue('--ks-primary')
  )

  const options = reactive({
    max: '100',
    values: '75',
    colors: themeColor.value,
    demo: 'Single',
    demos: ['Single', 'Segmented']
  })

  watch(
    () => options.demo,
    () => {
      if (options.demo === 'Single') {
        options.values = '75'
        options.colors = themeColor.value
      } else {
        options.values = '50,25,12'
        options.colors = '#FC6371,#EAC93A,#3D7E80'
      }
    }
  )
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <KsProgress
        :max="Number(options.max)"
        :values="options.values.split(',').map(Number)"
        :colors="options.colors.split(',')"
      />
    </template>
    <template #controls>
      <div />
      <div />
      <div>
        <fieldset>
          <legend>Demo</legend>
          <select v-model="options.demo">
            <option
              v-for="demoOption in options.demos"
              :key="demoOption"
              :value="demoOption"
              v-text="demoOption"
            />
          </select>
        </fieldset>
        <fieldset>
          <legend>Max</legend>
          <input
            v-model="options.max"
            type="text"
          />
        </fieldset>
        <fieldset>
          <legend>Values (comma-separated)</legend>
          <input
            v-model="options.values"
            type="text"
          />
        </fieldset>
        <fieldset>
          <legend>Colors (comma-separated)</legend>
          <input
            v-model="options.colors"
            type="text"
          />
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>
