<script setup lang="ts">
  import { ref, useAttrs } from 'vue'

  withDefaults(
    defineProps<{
      modelValue?: any
      shape?: string
    }>(),
    {
      shape: 'normal',
      modelValue: null
    }
  )

  const emit = defineEmits<{
    (event: 'update:modelValue', value: any): void
  }>()

  const input = ref()
  const attrs = useAttrs()

  function onInput({ target }: Event) {
    const input = target as HTMLInputElement
    let value = input.value as any
    if (attrs.type === 'radio') value = input.checked && input.value
    if (attrs.type === 'checkbox') value = input.checked
    emit('update:modelValue', value)
  }

  defineExpose({ el: input })
</script>

<template>
  <input
    ref="input"
    class="ks-input"
    :class="`ks-input-${shape}`"
    :value="modelValue"
    :checked="attrs.type === 'radio' ? modelValue === attrs.value : modelValue"
    @input="onInput"
  />
</template>

<style scoped>
  .ks-input {
    appearance: none;
    position: relative;
    height: 2.6em;
    width: 100%;
    max-width: 100%;
    background: var(--ks-input);
    color: var(--ks-text);
    border: 0;
    font: inherit;
    font-size: 1rem;
    box-shadow: none;
    outline: 0;
    margin: 0;
    padding: 0.6em 1.2em;
    accent-color: var(--ks-primary);
    transition: 0.2s;
  }

  .ks-input:not(:disabled):hover {
    background: var(--ks-inputhover);
  }

  .ks-input:disabled {
    cursor: not-allowed;
    color: var(--ks-text);
    opacity: 0.7;
  }

  .ks-input:not(:disabled):focus-visible {
    box-shadow: 0 0 0 3px var(--ks-focusring);
    background: var(--ks-inputfocus);
  }

  .ks-input-normal {
    border-radius: calc(12px * var(--ks-roundness));
  }

  .ks-input-rounded {
    border-radius: 50px;
  }

  .ks-input[type='file'] {
    cursor: pointer;
  }

  .ks-input[type='checkbox'],
  .ks-input[type='radio'] {
    appearance: revert;
    width: 1.3em;
    height: 1.3em;
    padding: 0;
    margin-right: 0.4em;
  }

  .ks-input[type='checkbox']:not(:disabled):hover,
  .ks-input[type='radio']:not(:disabled):hover {
    cursor: pointer;
    accent-color: var(--ks-secondary);
  }

  .ks-input[type='search']::-webkit-search-decoration,
  .ks-input[type='search']::-webkit-search-cancel-button,
  .ks-input[type='search']::-webkit-search-results-button,
  .ks-input[type='search']::-webkit-search-results-decoration {
    appearance: none;
  }

  .ks-input[type='range'] {
    appearance: revert;
  }

  .ks-input[type='range']:focus-visible {
    accent-color: var(--ks-secondary);
  }

  .ks-input[type='range']:not(:disabled):hover {
    cursor: pointer;
  }
</style>
