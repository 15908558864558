<script setup lang="ts">
  import { reactive, onMounted } from 'vue'
  import { KsInput, KsButton, KsPopover } from '../../lib/js'

  const settings = reactive(
    JSON.parse(localStorage.getItem('settings') || '{}')
  )
  const isThemePage =
    new URLSearchParams(window.location.search).get('page') === 'theming'
  const keys = [
    'primary',
    'primarytext',
    'secondary',
    'surface',
    'focusring',
    'text',
    'focus-ring',
    'roundness'
  ]

  function applySettings() {
    for (const key of keys) {
      const property = `--ks-${key}`
      const computedStyle = window.getComputedStyle(document.documentElement)
      const value = settings[key] || computedStyle.getPropertyValue(property)
      document.body.style.setProperty(property, value)
      settings[key] = value
    }
  }

  function resetSettings() {
    for (const key of keys) {
      const property = `--ks-${key}`
      const computedStyle = window.getComputedStyle(document.documentElement)
      document.body.style.removeProperty(property)
      settings[key] = computedStyle.getPropertyValue(property)
    }
    localStorage.setItem('settings', JSON.stringify(settings))
    applySettings()
  }

  function onInput() {
    localStorage.setItem('settings', JSON.stringify(settings))
    applySettings()
  }

  onMounted(applySettings)
</script>

<template>
  <KsPopover
    v-if="isThemePage"
    class="docs-theme-button"
  >
    <template #toggle>
      <KsButton
        variant="secondary"
        shape="square"
        raised
      >
        🎨
      </KsButton>
    </template>
    <template #content>
      <form
        class="docs-theme-inputs"
        @input="onInput"
      >
        <label>
          <KsInput
            v-model="settings['primary']"
            type="color"
          />
          Primary color
        </label>
        <label>
          <KsInput
            v-model="settings['primarytext']"
            type="color"
          />
          Primary text color
        </label>
        <label>
          <KsInput
            v-model="settings['secondary']"
            type="color"
          />
          Secondary color
        </label>
        <label>
          <KsInput
            v-model="settings['surface']"
            type="color"
          />
          Surface color
        </label>
        <label>
          <KsInput
            v-model="settings['text']"
            type="color"
          />
          Text color
        </label>
        <label>
          <KsInput
            v-model="settings['focusring']"
            type="color"
          />
          Focus ring color
        </label>
        <label>
          <KsInput
            v-model="settings['roundness']"
            type="range"
            min="0"
            max="2"
            step="0.1"
          />
          Roundness
          {{ settings['roundness'] }}
        </label>
        <KsButton
          variant="secondary"
          size="small"
          center
          @click="resetSettings"
        >
          Reset
        </KsButton>
      </form>
    </template>
  </KsPopover>
</template>

<style scoped>
  .docs-theme-button {
    position: fixed;
    top: 2em;
    right: 2em;
    z-index: 25;
  }

  .docs-theme-button .ks-popover-toggle {
    --ks-input: white;
  }

  .docs-theme-button .ks-popover-content {
    border-radius: 12px;
    max-height: initial;
  }

  .docs-theme-button label {
    font-size: 14px;
  }

  .docs-theme-button input {
    width: 5em;
    height: 2em;
    cursor: pointer;
  }

  .docs-theme-inputs {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .docs-theme-inputs label {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
</style>
