<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsTabs, KsTab } from '../../lib/js'

  const options = reactive({
    autoopen: true
  })
</script>

<template>
  <KsDocsComponent>
    <template #viewer>
      <KsTabs :autoopen="options.autoopen">
        <KsTab title="One">
          <p>This is tab one content</p>
        </KsTab>
        <KsTab title="Two">
          <KsTabs :autoopen="options.autoopen">
            <KsTab
              v-for="tab of ['one', 'two', 'three']"
              :key="tab"
              :title="`Nested ${tab}`"
            >
              <p>This is nested tab {{ tab }} content</p>
            </KsTab>
          </KsTabs>
        </KsTab>
        <KsTab
          title="Three disabled"
          disabled
        >
          <p>Hidden</p>
        </KsTab>
        <KsTab title="Four">
          <p>This is tab four content</p>
        </KsTab>
      </KsTabs>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <label>
            Auto open
            <input
              v-model="options.autoopen"
              class="ks-input"
              type="checkbox"
            />
          </label>
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>
