<script setup lang="ts">
  import { computed, ref } from 'vue'

  const props = withDefaults(
    defineProps<{
      values?: number[]
      colors?: string[]
      max?: number
    }>(),
    {
      values: () => [],
      colors: () => [],
      max: 100
    }
  )

  const progress = ref()

  const value = computed(() =>
    Math.min(
      props.max,
      props.values.reduce((prev, curr) => prev + curr, 0)
    )
  )
  const percentage = computed(
    () => (value.value / Math.max(props.max, 1)) * 100
  )

  defineExpose({ el: progress })
</script>

<template>
  <div
    ref="progress"
    class="ks-progress"
    role="progressbar"
    :aria-valuemin="0"
    :aria-valuemax="max"
    :aria-valuenow="value"
    :data-percentage="percentage"
  >
    <span
      v-for="(val, index) of values"
      :key="val"
      :style="{
        width: `${(val / max) * 100}%`,
        background: colors[index]
      }"
      aria-hidden="true"
    />
  </div>
</template>

<style scoped>
  .ks-progress {
    display: flex;
    width: 100%;
    min-width: 100px;
    height: 14px;
    border-radius: 50px;
    background: var(--ks-input);
    overflow: hidden;
  }

  .ks-progress span {
    margin: 2px 0;
    background: var(--ks-primary);
  }

  .ks-progress span:first-child {
    margin-left: 2px;
    border-top-left-radius: calc(0.5em * var(--ks-roundness));
    border-bottom-left-radius: calc(0.5em * var(--ks-roundness));
  }

  .ks-progress span:last-child {
    margin-right: 2px;
    border-top-right-radius: calc(0.5em * var(--ks-roundness));
    border-bottom-right-radius: calc(0.5em * var(--ks-roundness));
  }
</style>
