<script lang="ts" setup>
  import { ref } from 'vue'

  const item = ref()

  defineExpose({ el: item })
</script>

<template>
  <li
    ref="item"
    class="ks-carousel-item"
  >
    <slot />
  </li>
</template>

<style scoped>
  .ks-carousel-item {
    scroll-snap-align: start;
  }
</style>
