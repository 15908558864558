// import 'normalize.css'
import '../../lib/css/index.css'
import '../css/index.scss'
import { createApp } from 'vue'
import { KsToast, KsTooltip, KsIcon } from '../../lib/js'
import KsDocs from './KsDocs.vue'
import KsDocsHome from './KsDocsHome.vue'
import KsDocsExpand from './KsDocsExpand.vue'
import KsDocsButton from './KsDocsButton.vue'
import KsDocsBreadcrumbs from './KsDocsBreadcrumbs.vue'
import KsDocsCallout from './KsDocsCallout.vue'
import KsDocsCarousel from './KsDocsCarousel.vue'
import KsDocsDialog from './KsDocsDialog.vue'
import KsDocsDropdown from './KsDocsDropdown.vue'
import KsDocsInput from './KsDocsInput.vue'
import KsDocsPagination from './KsDocsPagination.vue'
import KsDocsToast from './KsDocsToast.vue'
import KsDocsPopover from './KsDocsPopover.vue'
import KsDocsProgress from './KsDocsProgress.vue'
import KsDocsSpinner from './KsDocsSpinner.vue'
import KsDocsTabs from './KsDocsTabs.vue'
import KsDocsTooltip from './KsDocsTooltip.vue'
import KsDocsTheming from './KsDocsTheming.vue'
import KsDocsBadge from './KsDocsBadge.vue'
import KsDocsIcon from './KsDocsIcon.vue'
import KsDocsDrawer from './KsDocsDrawer.vue'
import KsDocsSkeleton from './KsDocsSkeleton.vue'

const app = createApp(KsDocs)
app.use(KsToast)
app.directive('ks-tooltip', KsTooltip)
app.component('KsIcon', KsIcon)
app.component('KsDocsHome', KsDocsHome)
app.component('KsDocsIcon', KsDocsIcon)
app.component('KsDocsButton', KsDocsButton)
app.component('KsDocsBreadcrumbs', KsDocsBreadcrumbs)
app.component('KsDocsCallout', KsDocsCallout)
app.component('KsDocsCarousel', KsDocsCarousel)
app.component('KsDocsDialog', KsDocsDialog)
app.component('KsDocsDropdown', KsDocsDropdown)
app.component('KsDocsExpand', KsDocsExpand)
app.component('KsDocsInput', KsDocsInput)
app.component('KsDocsPagination', KsDocsPagination)
app.component('KsDocsPopover', KsDocsPopover)
app.component('KsDocsToast', KsDocsToast)
app.component('KsDocsProgress', KsDocsProgress)
app.component('KsDocsSpinner', KsDocsSpinner)
app.component('KsDocsTabs', KsDocsTabs)
app.component('KsDocsTooltip', KsDocsTooltip)
app.component('KsDocsTheming', KsDocsTheming)
app.component('KsDocsBadge', KsDocsBadge)
app.component('KsDocsDrawer', KsDocsDrawer)
app.component('KsDocsSkeleton', KsDocsSkeleton)
app.mount('#app')
