<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsSpinner } from '../../lib/js'

  const options = reactive({
    size: 'medium'
  })
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <KsSpinner
        :size="options.size"
        position="inline"
      />
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Size</legend>
          <label>
            Small
            <input
              v-model="options.size"
              type="radio"
              class="ks-input"
              name="size"
              value="small"
            />
          </label>
          <label>
            Medium
            <input
              v-model="options.size"
              type="radio"
              class="ks-input"
              name="size"
              value="medium"
            />
          </label>
        </fieldset>
      </div>
      <div />
    </template>
  </KsDocsComponent>
</template>
