<template>
  <figure>
    <svg
      viewBox="0 0 1118 559"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="279.5"
        cy="465.834"
        r="93.1667"
        fill="#6EAEB7"
      />
      <path
        d="M-3.34671e-06 372.667C24.4696 372.667 48.6997 367.847 71.3067 358.483C93.9137 349.119 114.455 335.393 131.758 318.091C149.06 300.788 162.785 280.247 172.15 257.64C181.514 235.033 186.333 210.803 186.333 186.333L4.79817e-06 186.333L-3.34671e-06 372.667Z"
        fill="#006A52"
      />
      <path
        d="M931.667 559C956.136 559 980.366 554.181 1002.97 544.817C1025.58 535.452 1046.12 521.727 1063.42 504.425C1080.73 487.122 1094.45 466.581 1103.82 443.974C1113.18 421.367 1118 397.137 1118 372.667L931.667 372.667L931.667 559Z"
        fill="#FC6371"
      />
      <path
        d="M372.667 -8.00994e-05C348.197 -8.03912e-05 323.967 4.81958 301.36 14.1837C278.753 23.5478 258.212 37.273 240.909 54.5757C223.606 71.8783 209.881 92.4196 200.517 115.027C191.153 137.634 186.333 161.864 186.333 186.333L372.667 186.333L372.667 -8.00994e-05Z"
        fill="#006A52"
      />
      <path
        d="M559 186.333C559 161.864 554.18 137.634 544.816 115.027C535.452 92.4197 521.727 71.8784 504.424 54.5758C487.122 37.2731 466.58 23.5479 443.973 14.1838C421.366 4.81966 397.136 3.20881e-06 372.667 0L372.667 186.333L559 186.333Z"
        fill="#F26C68"
      />
      <circle
        cx="465.833"
        cy="279.5"
        r="93.1667"
        fill="#00423E"
      />
      <circle
        cx="1024.83"
        cy="279.5"
        r="93.1667"
        fill="#00423E"
      />
      <circle
        cx="279.5"
        cy="279.5"
        r="93.1667"
        fill="#00423E"
      />
      <circle
        cx="838.5"
        cy="465.834"
        r="93.1667"
        fill="#6EAEB7"
      />
      <circle
        cx="93.1667"
        cy="465.834"
        r="93.1667"
        fill="#00423E"
      />
      <rect
        x="559"
        width="186.333"
        height="186.333"
        fill="#00423E"
      />
      <rect
        x="559"
        y="372.667"
        width="186.333"
        height="186.333"
        fill="#006A52"
      />
      <path
        d="M1118 186.333C1118 161.864 1113.18 137.634 1103.82 115.027C1094.45 92.4196 1080.73 71.8784 1063.42 54.5758C1046.12 37.2731 1025.58 23.5479 1002.97 14.1838C980.366 4.81965 956.136 -1.0696e-06 931.667 0L931.667 186.333L1118 186.333Z"
        fill="#00423E"
      />
      <path
        d="M931.667 -3.66987e-05C907.197 -3.69905e-05 882.967 4.81962 860.36 14.1837C837.753 23.5479 817.212 37.2731 799.909 54.5757C782.606 71.8784 768.881 92.4196 759.517 115.027C750.153 137.634 745.333 161.864 745.333 186.333L931.667 186.333L931.667 -3.66987e-05Z"
        fill="#FC6371"
      />
      <path
        d="M559 186.333H745.333V372.667H559V186.333Z"
        fill="#6EAEB7"
      />
    </svg>
  </figure>
</template>

<style scoped>
  figure {
    width: 100%;
    max-width: 750px;
  }
</style>
