<script lang="ts">
  import { ref, onMounted, h, compile } from 'vue'
  import { parseMarkdown } from './markdown'
  import KsDocsThemeButton from './KsDocsThemeButton.vue'

  let html = ref('')

  async function ajax(url: string): Promise<string> {
    const response = await fetch(url)
    if (!response.ok) {
      const errorMessage = `${response.status} ${response.statusText}: ${url}`
      throw new Error(errorMessage)
    } else {
      return response.text()
    }
  }

  async function renderPage() {
    const queryStrings = new URLSearchParams(window.location.search)
    const pageId = queryStrings.get('page') || 'home'
    let pageHtml
    try {
      pageHtml = await ajax(`/markdown/${pageId}.md`)
      const title = pageId.charAt(0).toUpperCase() + pageId.slice(1)
      document.title = `${title} - Kloss`
    } catch (error) {
      pageHtml = `# Oops!️\n${error}`
    }
    html.value = parseMarkdown(pageHtml, { cssPrefix: 'ks-docs' })
  }

  function annotatePage() {
    const links = document.querySelectorAll<HTMLAnchorElement>('nav a')
    for (const link of Array.from(links)) {
      const linkParams = new URLSearchParams(link.search)
      const params = new URLSearchParams(window.location.search)
      const page = params.get('page') || 'home'
      if (page === linkParams.get('page')) {
        link.setAttribute('aria-current', 'page')
      }
    }
  }

  function onMount() {
    renderPage()
    annotatePage()
  }

  export default {
    setup() {
      onMounted(onMount)
    },
    render() {
      return h('div', { class: 'ks-docs-design' }, [
        h(KsDocsThemeButton),
        h('div', { class: 'ks-docs-top' }),
        h('nav', [
          h('ul', [
            h('li', [h('a', { href: '?page=home' }, 'Kloss')]),
            h('li', [h('a', { href: '?page=index' }, 'Index')]),
            h('li', [h('a', { href: '?page=icons' }, 'Icons')]),
            h('li', [h('a', { href: '?page=theming' }, 'Theming')]),
            h('br'),
            h('li', [h('a', { href: '?page=badge' }, 'Badge')]),
            h('li', [h('a', { href: '?page=button' }, 'Button')]),
            h('li', [h('a', { href: '?page=breadcrumbs' }, 'Breadcrumbs')]),
            h('li', [h('a', { href: '?page=callout' }, 'Callout')]),
            h('li', [h('a', { href: '?page=carousel' }, 'Carousel')]),
            h('li', [h('a', { href: '?page=dialog' }, 'Dialog')]),
            h('li', [h('a', { href: '?page=drawer' }, 'Drawer')]),
            h('li', [h('a', { href: '?page=dropdown' }, 'Dropdown')]),
            h('li', [h('a', { href: '?page=expand' }, 'Expand')]),
            h('li', [h('a', { href: '?page=icon' }, 'Icon')]),
            h('li', [h('a', { href: '?page=input' }, 'Input')]),
            h('li', [h('a', { href: '?page=pagination' }, 'Pagination')]),
            h('li', [h('a', { href: '?page=popover' }, 'Popover')]),
            h('li', [h('a', { href: '?page=progress' }, 'Progress')]),
            h('li', [h('a', { href: '?page=spinner' }, 'Spinner')]),
            h('li', [h('a', { href: '?page=skeleton' }, 'Skeleton')]),
            h('li', [h('a', { href: '?page=tabs' }, 'Tabs')]),
            h('li', [h('a', { href: '?page=toast' }, 'Toast')]),
            h('li', [h('a', { href: '?page=tooltip' }, 'Tooltip')]),
            h('br'),
            h('li', [
              h(
                'a',
                {
                  href: 'https://aunivers.slack.com/archives/C02CY2XKQ69',
                  target: '_blank'
                },
                'Slack channel'
              )
            ]),
            h('li', [
              h(
                'a',
                {
                  href: 'https://gitlab.com/aschehoug/aschehoug-utdanning/kloss/',
                  target: '_blank'
                },
                'Gitlab repository'
              )
            ]),
            h('li', [h('a', { href: '?page=contributing' }, 'Contributing')])
          ])
        ]),
        h('main', h(compile(html.value)))
      ])
    }
  }
</script>
