<script setup lang="ts">
  import { KsIcon } from '.'
  import { ref } from 'vue'
  import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

  defineProps<{
    items: { title: string; href: string }[]
  }>()

  const breadcrumbs = ref()

  defineExpose({ el: breadcrumbs })
</script>

<template>
  <nav
    ref="breadcrumbs"
    aria-label="Brødsmulesti"
  >
    <ol class="ks-breadcrumbs">
      <li
        v-for="({ title, href }, index) of items"
        :key="href"
      >
        <a
          :href="href"
          :aria-current="index === items.length - 1 && 'page'"
          v-text="title"
        />
        <KsIcon
          v-if="index < items.length - 1"
          :icon="faChevronRight"
        />
      </li>
    </ol>
  </nav>
</template>

<style scoped>
  .ks-breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    margin: 0;
    font-size: 0.9rem;
  }

  .ks-breadcrumbs li {
    display: flex;
    align-items: center;
  }

  .ks-breadcrumbs li a {
    color: var(--ks-primary);
    font: inherit;
    text-decoration: none;
  }

  .ks-breadcrumbs li a[aria-current='page'] {
    color: var(--ks-text);
    pointer-events: none;
  }

  .ks-breadcrumbs li a:hover {
    text-decoration: underline;
  }

  .ks-breadcrumbs li a:focus-visible {
    box-shadow: 0 0 0 3px var(--ks-focusring);
  }

  .ks-breadcrumbs li svg {
    color: var(--ks-text);
    font-size: 0.5em;
    margin: 1.8em 1.2em;
  }
</style>
