<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsButton, KsDrawer } from '../../lib/js'
  import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

  const options = reactive({
    open: false,
    timeDateOpen: false,
    accountOpen: false,
    backdrop: true,
    dismissable: true,
    position: 'right',
    size: '450px',
    demo: 'Single',
    demos: ['Single', 'Layered']
  })
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <div>
        <KsButton
          variant="secondary"
          size="large"
          shape="rounded"
          @click="options.open = !options.open"
        >
          Open drawer
        </KsButton>
        <Teleport to=".ks-docs-top">
          <KsDrawer
            v-if="options.demo === 'Single'"
            title="Notes"
            :open="options.open"
            :backdrop="options.backdrop"
            :position="options.position"
            :size="options.size"
            :dismissable="options.dismissable"
            @close="options.open = false"
          >
            <template #body>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed
                id semper risus in hendrerit gravida rutrum quisque non. Diam
                vel quam elementum pulvinar etiam non quam.
              </p>
              <p>
                Tellus in metus vulputate eu scelerisque felis. Convallis aenean
                et tortor at risus viverra adipiscing. Malesuada bibendum arcu
                vitae elementum curabitur. Adipiscing tristique risus nec
                feugiat. Proin nibh nisl condimentum id.
              </p>
            </template>
          </KsDrawer>
          <KsDrawer
            v-if="options.demo === 'Layered'"
            title="Settings"
            :open="options.open"
            :backdrop="options.backdrop"
            :position="options.position"
            :dismissable="options.dismissable"
            @close="options.open = false"
          >
            <template #body>
              <div class="docs-drawer-settings">
                <KsButton
                  variant="secondary"
                  :icon-right="faChevronRight"
                  @click="options.accountOpen = true"
                >
                  Account
                </KsButton>
                <KsButton
                  variant="secondary"
                  :icon-right="faChevronRight"
                  @click="options.timeDateOpen = true"
                >
                  Time and date
                </KsButton>
              </div>
              <Teleport to=".ks-docs-top">
                <KsDrawer
                  title="Account"
                  :open="options.accountOpen"
                  :backdrop="false"
                  :position="options.position"
                  @close="options.accountOpen = false"
                >
                  <template #header>
                    <KsButton
                      shape="round"
                      iconLeft="arrow-left"
                      aria-label="Tilbake"
                      title="Tilbake"
                      @click="options.accountOpen = false"
                    />
                  </template>
                  <template #body> Account settings... </template>
                </KsDrawer>
                <KsDrawer
                  title="Time and date"
                  :open="options.timeDateOpen"
                  :backdrop="false"
                  :position="options.position"
                  @close="options.timeDateOpen = false"
                >
                  <template #header>
                    <KsButton
                      shape="round"
                      iconLeft="arrow-left"
                      aria-label="Tilbake"
                      title="Tilbake"
                      @click="options.timeDateOpen = false"
                    />
                  </template>
                  <template #body>Time and date settings...</template>
                </KsDrawer>
              </Teleport>
            </template>
          </KsDrawer>
        </Teleport>
      </div>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Demo</legend>
          <select v-model="options.demo">
            <option
              v-for="demoOption in options.demos"
              :key="demoOption"
              :value="demoOption"
              v-text="demoOption"
            />
          </select>
        </fieldset>
        <fieldset>
          <legend>Position</legend>
          <label>
            Left
            <input
              v-model="options.position"
              type="radio"
              value="left"
            />
          </label>
          <label>
            Right
            <input
              v-model="options.position"
              type="radio"
              value="right"
            />
          </label>
          <label>
            Top
            <input
              v-model="options.position"
              type="radio"
              value="top"
            />
          </label>
          <label>
            Bottom
            <input
              v-model="options.position"
              type="radio"
              value="bottom"
            />
          </label>
        </fieldset>
      </div>
      <div>
        <label>
          Backdrop
          <input
            v-model="options.backdrop"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Dismissable
          <input
            v-model="options.dismissable"
            class="ks-input"
            type="checkbox"
          />
        </label>
      </div>
      <div>
        <fieldset>
          <legend>Size</legend>
          <input
            v-model="options.size"
            type="text"
            placeholder="..."
            @keydown.enter.prevent=""
          />
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>

<style scoped>
  .docs-drawer-settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
</style>
