<script setup lang="ts">
  import { ref } from 'vue'

  const badge = ref()

  defineExpose({ el: badge })
</script>

<template>
  <sup
    ref="badge"
    class="ks-badge"
  >
    <slot />
  </sup>
</template>

<style scoped>
  .ks-badge {
    position: absolute;
    top: -1em;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.4em 0.6em;
    line-height: 1;
    color: var(--ks-primarytext);
    background: var(--ks-primary);
    font-size: 0.7rem;
    border-radius: 50px;
    border: 2px solid white;
    transition: 0.2s;
  }
</style>
