<script setup lang="ts">
  import { computed, ref, provide } from 'vue'

  export type Expand = { multiple: boolean }

  const props = withDefaults(
    defineProps<{
      size?: string
      multiple?: boolean
    }>(),
    {
      size: 'medium',
      multiple: true
    }
  )

  const expand = ref()
  const state = computed<Expand>(() => ({ multiple: props.multiple }))

  provide('ks-expand', state)
  defineExpose({ el: expand })
</script>

<template>
  <div
    ref="expand"
    class="ks-expand"
    :class="`ks-expand-${size}`"
  >
    <slot />
  </div>
</template>

<style scoped>
  .ks-expand {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    width: 100%;
  }

  .ks-expand-small {
    font-size: 0.9rem;
  }

  .ks-expand-medium {
    font-size: 1rem;
  }

  .ks-expand-large {
    font-size: 1.1rem;
  }
</style>
