import { marked } from 'marked'

export function parseMarkdown(str: string, opts = { cssPrefix: 'md' }): string {
  const renderer = new marked.Renderer()
  const { cssPrefix } = opts
  renderer.heading = (text: string, level: string) =>
    `<h${level} class="${cssPrefix}-heading ${cssPrefix}-heading--${level}">${text}</h${level}>`
  renderer.table = (thead: string, tbody: string) =>
    `<table class="${cssPrefix}-table"><thead>${thead}</thead><tbody>${tbody}</tbody></table>`
  renderer.blockquote = (text: string) =>
    `<blockquote class="${cssPrefix}-quote">${text}</blockquote>`
  renderer.paragraph = (text: string) => `<p class="${cssPrefix}-p">${text}</p>`
  renderer.list = (body: string) => `<ul class="${cssPrefix}-list">${body}</ul>`
  renderer.hr = () => `<hr class="${cssPrefix}-ruler" aria-hidden="true">`
  renderer.code = (code: string) =>
    `<pre class="${cssPrefix}-pre">${code
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')}</pre>`
  renderer.link = (href: string, title: string, text: string) =>
    `<a class="${cssPrefix}-link" href=${href}>${text}</a>`
  marked.setOptions({ renderer })
  return marked.parse(str)
}
