<script setup lang="ts">
  import { onMounted, ref, nextTick } from 'vue'
  import { KsButton } from '.'
  import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'

  const leftButtonHidden = ref(true)
  const rightButtonHidden = ref(true)
  const carousel = ref()
  const list = ref()

  function onClick(direction: string) {
    const scrollAmount = list.value.getBoundingClientRect().width
    list.value.scroll({
      left:
        list.value.scrollLeft +
        (direction === 'left' ? -scrollAmount : scrollAmount)
    })
  }

  function onScroll() {
    if (!list.value) return
    const ul = list.value
    if (!ul.firstElementChild) return
    leftButtonHidden.value = ul.scrollLeft <= 1
    rightButtonHidden.value =
      Math.floor(ul.scrollWidth - ul.scrollLeft) <=
      Math.ceil(ul.getBoundingClientRect().width)
  }

  onMounted(async () => {
    await nextTick()
    onScroll()
  })

  defineExpose({ el: carousel })
</script>

<template>
  <div
    ref="carousel"
    role="region"
    class="ks-carousel"
  >
    <div class="ks-carousel-buttons">
      <KsButton
        class="ks-carousel-button ks-carousel-button-left"
        variant="secondary"
        shape="square"
        :icon-left="faArrowLeft"
        aria-label="Rull mot venstre"
        aria-hidden="true"
        :hidden="leftButtonHidden"
        @click="onClick('left')"
        raised
      />
      <KsButton
        class="ks-carousel-button ks-carousel-button-right"
        variant="secondary"
        shape="square"
        :icon-left="faArrowRight"
        aria-label="Rull mot høyre"
        aria-hidden="true"
        :hidden="rightButtonHidden"
        @click="onClick('right')"
        raised
      />
    </div>
    <ul
      ref="list"
      class="ks-carousel-list"
      @scroll="onScroll"
    >
      <slot />
    </ul>
  </div>
</template>

<style scoped>
  .ks-carousel {
    position: relative;
    margin: 0 -1rem;
  }

  .ks-carousel-button {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--ks-surface);
  }

  .ks-carousel-button[hidden] {
    display: inline-flex;
    visibility: hidden;
    opacity: 0;
  }

  .ks-carousel-button-left {
    left: -1rem;
  }

  .ks-carousel-button-right {
    right: -1rem;
  }

  .ks-carousel-buttons {
    --ks-input: var(--ks-surface);
    --ks-inputhover: var(--ks-surface);
  }

  .ks-carousel-list {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    list-style: none;
    gap: 1.3rem;
    margin: 0;
    padding: 1.2rem 1rem;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scroll-padding: 2rem;
    scrollbar-width: none;
  }

  ul::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 640px) {
    .ks-carousel-button {
      display: inline-flex;
    }
  }
</style>
