<script setup lang="ts">
  import { reactive, watch } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsCallout } from '../../lib/js'
  import type { KsCalloutVariant } from '../../lib/js/KsCallout.vue'

  const options = reactive({
    variant: 'warning' as KsCalloutVariant,
    title: 'Your subscription is about to expire',
    text: 'To continue using this product you need to renew your subscription. Head to our webshop and contact <a href="#">customer service</a> if you have any questions.',
    dismissable: true
  })

  watch(
    () => options.variant,
    () => {
      if (options.variant === 'info') {
        options.title = 'Did you know?'
        options.text =
          'Your created plans in the planner can be shared freely with groups and selected users. You can also ask for permission to access plans created by others.'
      }
      if (options.variant === 'success') {
        options.title = 'Plan created'
        options.text =
          'Your plan "<a href="#">Fabel 6</a>" scheduled for week 4–8 was created. It is visible only to you. To share with colleagues, use the sharing function.'
      }
      if (options.variant === 'warning') {
        options.title = 'Your subscription is about to expire'
        options.text =
          'To continue using this product you need to renew your subscription. Head to our webshop and contact <a href="#">customer service</a> if you have any questions.'
      }
      if (options.variant === 'error') {
        options.title = "Oops! Something isn't right"
        options.text =
          'Got an error when attempting to fetch your products. Error message: 404 Not found. If the problem persists, please contact customer support and provide the error message.'
      }
    }
  )
</script>

<template>
  <KsDocsComponent>
    <template #viewer>
      <div>
        <KsCallout
          :variant="options.variant"
          :title="options.title"
          :dismissable="options.dismissable"
          class="docs-callout"
        >
          <div v-html="options.text" />
        </KsCallout>
      </div>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Variant</legend>
          <label>
            Info
            <input
              v-model="options.variant"
              type="radio"
              class="ks-input"
              name="variant"
              value="info"
            />
          </label>
          <label>
            Success
            <input
              v-model="options.variant"
              type="radio"
              class="ks-input"
              name="variant"
              value="success"
            />
          </label>
          <label>
            Warning
            <input
              v-model="options.variant"
              type="radio"
              class="ks-input"
              name="variant"
              value="warning"
            />
          </label>
          <label>
            Error
            <input
              v-model="options.variant"
              type="radio"
              class="ks-input"
              name="variant"
              value="error"
            />
          </label>
        </fieldset>
        <fieldset>
          <label>
            Dismissable
            <input
              v-model="options.dismissable"
              class="ks-input"
              type="checkbox"
            />
          </label>
        </fieldset>
      </div>
      <div />
      <div>
        <fieldset>
          <legend>Title</legend>
          <input
            v-model="options.title"
            type="text"
          />
        </fieldset>
        <fieldset>
          <legend>Text</legend>
          <textarea
            v-model="options.text"
            rows="5"
          />
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>

<style>
  .docs-callout button {
    pointer-events: none;
  }
</style>
