<script setup lang="ts">
  import { KsButton } from '.'
  import { computed, ref } from 'vue'
  import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'

  const props = defineProps<{
    currentPage: number
    itemsPerPage: number
    itemCount: number
  }>()

  const emit = defineEmits(['pushPage'])

  const maxVisibleButtons = ref(3)

  const totalPages = computed(() =>
    Math.ceil(props.itemCount / props.itemsPerPage)
  )
  const offset = computed(() => (props.currentPage - 1) * props.itemsPerPage)
  const hasNextPage = computed(
    () => offset.value + props.itemsPerPage < props.itemCount
  )
  const hasPrevPage = computed(() => props.currentPage > 1)

  const startPage = computed(() => {
    if (props.currentPage === 1) return 1
    if (props.currentPage === totalPages.value) {
      const start = totalPages.value - (maxVisibleButtons.value - 1)
      return start === 0 ? 1 : start
    }
    return props.currentPage - 1
  })

  const pages = computed(() => {
    const range = []
    for (
      let i = startPage.value;
      i <=
      Math.min(startPage.value + maxVisibleButtons.value - 1, totalPages.value);
      i++
    ) {
      range.push(i)
    }
    return range
  })

  const pushFirstPage = () => emit('pushPage', 1)
  const pushPreviousPage = () => emit('pushPage', props.currentPage - 1)
  const pushExactPage = (page: number) => emit('pushPage', page)
  const pushNextPage = () => emit('pushPage', props.currentPage + 1)
  const pushLastPage = () => emit('pushPage', totalPages.value)
</script>

<template>
  <nav
    role="navigation"
    aria-label="Sidenavigasjon"
  >
    <ul>
      <li>
        <KsButton
          variant="secondary"
          :icon-left="faArrowLeft"
          shape="square"
          aria-label="Forrige side"
          :disabled="!hasPrevPage"
          @click.prevent="pushPreviousPage"
        />
      </li>

      <li v-if="currentPage >= maxVisibleButtons">
        <KsButton
          variant="secondary"
          aria-label="Side 1"
          shape="square"
          @click.prevent="pushFirstPage"
        >
          1
        </KsButton>
      </li>

      <li
        v-if="currentPage >= maxVisibleButtons + 1"
        aria-hidden="true"
        class="ks-pagination-dots"
        v-text="'...'"
      />

      <li
        v-for="pageNumber in pages"
        :key="`page-${pageNumber}`"
      >
        <KsButton
          :variant="currentPage === pageNumber ? 'primary' : 'secondary'"
          :aria-label="`Side ${pageNumber}`"
          shape="square"
          :aria-current="currentPage === pageNumber"
          @click.prevent="pushExactPage(pageNumber)"
        >
          {{ pageNumber }}
        </KsButton>
      </li>

      <li
        v-if="!pages.includes(totalPages)"
        aria-hidden="true"
        class="ks-pagination-dots"
        v-text="'...'"
      />

      <li v-if="!pages.includes(totalPages)">
        <KsButton
          variant="secondary"
          :aria-label="`Side ${totalPages}`"
          shape="square"
          @click.prevent="pushLastPage"
        >
          {{ totalPages }}
        </KsButton>
      </li>

      <li>
        <KsButton
          variant="secondary"
          shape="square"
          :icon-right="faArrowRight"
          aria-label="Neste side"
          :disabled="!hasNextPage"
          @click.prevent="pushNextPage"
        />
      </li>
    </ul>
  </nav>
</template>

<style scoped>
  ul {
    display: flex;
    gap: 1rem;
  }
  ul li {
    list-style: none;
  }
  .ks-pagination-dots {
    padding: 0.5em 0;
  }
</style>
