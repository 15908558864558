<script setup lang="ts">
  import { ref } from 'vue'

  withDefaults(
    defineProps<{
      size?: string
      position?: string
    }>(),
    {
      size: 'medium',
      position: 'inline'
    }
  )

  const spinner = ref()

  defineExpose({ el: spinner })
</script>

<template>
  <div
    ref="spinner"
    class="ks-spinner"
    :class="[`ks-spinner-${size}`, `ks-spinner-${position}`]"
    role="status"
    tabindex="0"
  >
    <div>
      <span class="ks-sr-only">Laster...</span>
    </div>
  </div>
</template>

<style scoped>
  .ks-spinner {
    display: inline-block;
  }

  .ks-spinner-small {
    width: 1.6em;
    height: 1.6em;
  }

  .ks-spinner-medium {
    width: 3em;
    height: 3em;
  }

  .ks-spinner-center {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

  .ks-spinner div {
    width: 100%;
    height: 100%;
    border: 0.2em solid var(--ks-primary);
    border-radius: 50px;
    border-right-color: transparent;
    animation: ks-spin 1s linear infinite;
  }
</style>
