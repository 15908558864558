<script setup lang="ts">
  import { reactive } from 'vue'
  import { KsPagination } from '../../lib/js'
  import KsDocsComponent from './KsDocsComponent.vue'

  const options = reactive({
    currentPage: 1,
    itemsPerPage: 10,
    itemCount: 150
  })

  const pushPage = (pageNumber: number) => (options.currentPage = pageNumber)
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <KsPagination
        :current-page="options.currentPage"
        :items-per-page="options.itemsPerPage"
        :item-count="options.itemCount"
        @push-page="pushPage"
      />
    </template>
  </KsDocsComponent>
</template>
