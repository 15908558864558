<script setup lang="ts">
  import { ref } from 'vue'

  withDefaults(
    defineProps<{
      modelValue?: any
    }>(),
    {
      modelValue: null
    }
  )

  const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
  }>()

  const input = ref()

  function onInput({ target }: Event) {
    const input = target as HTMLInputElement
    emit('update:modelValue', input.checked)
  }

  defineExpose({ el: input })
</script>

<template>
  <input
    ref="input"
    class="ks-switch"
    type="checkbox"
    @input="onInput"
  />
</template>

<style scoped>
  .ks-switch {
    appearance: none;
    background-color: transparent;
    background-repeat: repeat;
    background-image: radial-gradient(
      circle at 30%,
      white 25%,
      currentColor 30%,
      currentColor
    );
    border: 0;
    border-radius: 50px;
    color: var(--ks-primary);
    cursor: pointer;
    height: 1.8em;
    width: 3em;
    padding: 0;
    opacity: 0.7;
    margin-right: 1em;
    transition: 0.2s background-position;
  }

  .ks-switch:checked {
    opacity: 1;
    background-position-x: 1.2em;
  }

  .ks-switch:disabled {
    filter: grayscale(100%);
    opacity: 0.4;
    cursor: not-allowed;
  }

  .ks-switch:disabled:checked {
    background: var(--ks-secondary);
  }

  .ks-switch:not(:disabled):hover {
    opacity: 0.7;
  }

  .ks-switch:focus-visible {
    box-shadow: 0 0 0 3px var(--ks-focusring);
  }
</style>
