<script setup lang="ts">
  import { ref, computed, useSlots, provide } from 'vue'
  import { KsButton } from '.'
  import type { Tab } from './KsTab.vue'
  import { uid, htmlBool } from './utils'

  const props = withDefaults(
    defineProps<{
      autoopen?: boolean
    }>(),
    {
      autoopen: true
    }
  )

  const slots = useSlots()
  const openTab = ref(0)
  const tabsEl = ref()

  const tabs = computed<Tab[]>(() => {
    const slot = slots.default ? slots.default() : []
    return slot
      .flatMap((s) => (Array.isArray(s.children) ? s.children : s))
      .map((tab: any, index) => ({
        title: tab.props?.title ?? '',
        uuid: uid(),
        open: openTab.value === index,
        disabled: htmlBool(tab.props?.disabled)
      }))
  })

  function onKeyDown({ target, key }: KeyboardEvent) {
    const button = target as HTMLElement
    const tablist = button.parentNode as HTMLElement
    const buttons = tablist.querySelectorAll<HTMLElement>(
      '.ks-tab:not(:disabled)'
    )
    const index = Array.from(buttons).indexOf(button)
    let nextButton
    if (key === 'ArrowLeft') nextButton = buttons[index - 1]
    if (key === 'ArrowRight') nextButton = buttons[index + 1]
    if (key === 'Home') nextButton = buttons[0]
    if (key === 'End') nextButton = buttons[buttons.length - 1]
    nextButton?.focus()
  }

  function onFocus(index: number) {
    if (!props.autoopen) return
    openTab.value = index
  }

  function onClick(index: number) {
    openTab.value = index
  }

  provide('ks-tabs', tabs)

  defineExpose({ el: tabsEl })
</script>

<template>
  <div class="ks-tabs">
    <div role="tablist">
      <KsButton
        v-for="(tab, index) of tabs"
        :id="`ks-tab-${tab.uuid}`"
        :key="index"
        class="ks-tab"
        :variant="tab.open ? 'primary' : 'tertiary'"
        role="tab"
        :aria-controls="`ks-tabpanel-${tab.uuid}`"
        :aria-selected="tab.open"
        :tabindex="tab.open ? 0 : -1"
        :disabled="tab.disabled"
        center
        @keydown.left.right.home.end.prevent="onKeyDown"
        @focus="onFocus(index)"
        @click="onClick(index)"
      >
        {{ tab.title }}
      </KsButton>
    </div>
    <slot />
  </div>
</template>

<style scoped>
  .ks-tabs {
    width: 100%;
  }

  .ks-tabs [role='tablist'] {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.2rem;
  }
</style>
