import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowLeft as fasrFaArrowLeft,
  faArrowRight as fasrFaArrowRight,
  faArrowUp as fasrFaArrowUp,
  faArrowDown as fasrFaArrowDown,
  faChevronDown as fasrFaChevronDown,
  faChevronUp as fasrFaChevronUp,
  faFolder as fasrFaFolder,
  faArrowUpRightFromSquare as fasrFaArrowUpRightFromSquare,
  faBars as fasrFaBars,
  faList as fasrFaList,
  faCheck as fasrFaCheck,
  faMessage as fasrFaMessage,
  faXmark as fasrFaXmark,
  faMinus as fasrFaMinus,
  faHome as fasrFaHome,
  faVideo as fasrFaVideo,
  faHeadphones as fasrFaHeadphones,
  faUser as fasrFaUser,
  faCamera as fasrFaCamera,
  faGlassesRound as fasrFaGlassesRound,
  faBookOpen as fasrFaBookOpen,
  faCog as fasrFaCog,
  faMagnifyingGlass as fasrFaMagnifyingGlass,
  faMusic as fasrFaMusic,
  faPause as fasrFaPause,
  faPlus as fasrFaPlus,
  faPlay as fasrFaPlay,
  faArrowDownToLine as fasrFaArrowDownToLine,
  faArrowUpRightAndArrowDownLeftFromCenter as fasrFaArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter as fasrFaArrowDownLeftAndArrowUpRightToCenter,
  faGrid2 as fasrFaGrid2,
  faForwardStep as fasrFaForwardStep,
  faBackwardStep as fasrFaBackwardStep,
  faMedal as fasrFaMedal,
  faHandPointUp as fasrFaHandPointUp
} from '@fortawesome/sharp-regular-svg-icons'

library.add(
  fasrFaArrowLeft,
  fasrFaArrowRight,
  fasrFaArrowUp,
  fasrFaArrowDown,
  fasrFaChevronDown,
  fasrFaChevronUp,
  fasrFaFolder,
  fasrFaArrowUpRightFromSquare,
  fasrFaBars,
  fasrFaList,
  fasrFaCheck,
  fasrFaMessage,
  fasrFaXmark,
  fasrFaMinus,
  fasrFaHome,
  fasrFaVideo,
  fasrFaHeadphones,
  fasrFaUser,
  fasrFaCamera,
  fasrFaGlassesRound,
  fasrFaBookOpen,
  fasrFaCog,
  fasrFaMagnifyingGlass,
  fasrFaMusic,
  fasrFaPause,
  fasrFaPlus,
  fasrFaPlay,
  fasrFaArrowDownToLine,
  fasrFaArrowUpRightAndArrowDownLeftFromCenter,
  fasrFaArrowDownLeftAndArrowUpRightToCenter,
  fasrFaGrid2,
  fasrFaForwardStep,
  fasrFaBackwardStep,
  fasrFaMedal,
  fasrFaHandPointUp
)
