<script setup lang="ts">
  import { inject, computed, type ComputedRef, ref } from 'vue'

  export type Tab = {
    title: string
    uuid: string
    open: boolean
    disabled: boolean
  }

  const props = withDefaults(
    defineProps<{
      title: string
      disabled?: boolean
    }>(),
    {
      disabled: false
    }
  )

  const tabs = inject('ks-tabs') as ComputedRef<Tab[]>
  const tab = computed(
    () => tabs.value.find((tab) => tab.title === props.title) as Tab
  )

  const tabEl = ref()

  defineExpose({ el: tabEl })
</script>

<template>
  <div
    ref="tabEl"
    :id="`ks-tabpanel-${tab.uuid}`"
    class="ks-tabpanel"
    role="tabpanel"
    :hidden="!tab.open"
    :aria-labelledby="`ks-tab-${tab.uuid}`"
  >
    <slot />
  </div>
</template>
