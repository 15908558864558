<script setup lang="ts">
  import { ref, reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsDialog, KsButton, KsInput } from '../../lib/js'
  import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons'

  const options = reactive({
    title: 'Save file',
    open: false,
    strict: false,
    backdrop: true,
    size: 'small',
    dismissable: true,
    demo: 'Footer',
    demos: ['Footer', 'No footer']
  })

  const dialogInput = ref('Notes.docx')
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <div>
        <KsButton
          variant="primary"
          size="large"
          @click="options.open = !options.open"
        >
          Open dialog
        </KsButton>
        <Teleport to=".ks-docs-top">
          <KsDialog
            :title="options.title"
            :open="options.open"
            :strict="options.strict"
            :backdrop="options.backdrop"
            :size="options.size"
            :dismissable="options.dismissable"
            @close="options.open = false"
          >
            <template #body>
              <div class="docs-dialog-content">
                <span>You have unsaved changes. Do you want to save file?</span>
                <label>
                  File name
                  <KsInput
                    v-model="dialogInput"
                    type="text"
                    placeholder="..."
                  />
                </label>
              </div>
            </template>
            <template
              v-if="options.demo === 'Footer'"
              #footer
            >
              <div class="docs-dialog-footer">
                <KsButton
                  variant="tertiary"
                  shape="rounded"
                  @click="options.open = !options.open"
                >
                  Cancel
                </KsButton>
                <KsButton
                  variant="primary"
                  shape="rounded"
                  :icon-left="faFloppyDisk"
                  @click="options.open = !options.open"
                >
                  Save
                </KsButton>
              </div>
            </template>
          </KsDialog>
        </Teleport>
      </div>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Demo</legend>
          <select v-model="options.demo">
            <option
              v-for="demoOption in options.demos"
              :key="demoOption"
              :value="demoOption"
              v-text="demoOption"
            />
          </select>
        </fieldset>
        <fieldset>
          <legend>Size</legend>
          <label>
            Small
            <input
              v-model="options.size"
              type="radio"
              class="ks-input"
              name="size"
              value="small"
            />
          </label>
          <label>
            Medium
            <input
              v-model="options.size"
              type="radio"
              class="ks-input"
              name="size"
              value="medium"
            />
          </label>
          <label>
            Large
            <input
              v-model="options.size"
              type="radio"
              class="ks-input"
              name="size"
              value="large"
            />
          </label>
        </fieldset>
        <fieldset>
          <legend>Title</legend>
          <input
            v-model="options.title"
            type="text"
            placeholder="Title..."
          />
        </fieldset>
      </div>
      <div>
        <label>
          Backdrop
          <input
            v-model="options.backdrop"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Strict
          <input
            v-model="options.strict"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Dismissable
          <input
            v-model="options.dismissable"
            class="ks-input"
            type="checkbox"
          />
        </label>
      </div>
    </template>
  </KsDocsComponent>
</template>

<style scoped>
  .docs-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .docs-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }
</style>
