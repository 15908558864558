export const FOCUSABLE =
  '[tabindex],a,button:not([disabled]),input:not([disabled]),select:not([disabled]),textarea:not([disabled]),summary,audio,video,iframe,area,[contenteditable],[draggable]'

export function uid() {
  return Date.now().toString(36) + Math.random().toString(36).slice(2, 5)
}

export function htmlBool(value: any) {
  if (typeof value === 'undefined') return false
  if (typeof value === 'string') return value === ''
  if (typeof value === 'boolean') return value
  return false
}
