<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsExpand, KsExpandItem } from '../../lib/js'

  const options = reactive({
    size: 'medium',
    multiple: true,
    demo: 'Simple',
    demos: ['Simple', 'Custom toggle']
  })
</script>

<template>
  <KsDocsComponent>
    <template #viewer>
      <KsExpand
        v-if="options.demo === 'Simple'"
        :key="`${options.size}`"
        :size="options.size"
        :multiple="options.multiple"
      >
        <KsExpandItem title="One">
          <template #content>
            <div>
              One At nam quia voluptatem quia ut. Vero laudantium provident
              dolor voluptatem ut quis voluptatem. Praesentium facilis eligendi
              quis.
            </div>
          </template>
        </KsExpandItem>
        <KsExpandItem title="Two">
          <template #content>
            <div>
              Two At nam quia voluptatem quia ut. Vero laudantium provident
              dolor voluptatem ut quis voluptatem. Praesentium facilis eligendi
              quis.
            </div>
          </template>
        </KsExpandItem>
        <KsExpandItem
          title="Three disabled"
          disabled
        >
          <template #content>
            <div>
              Three At nam quia voluptatem quia ut. Vero laudantium provident
              dolor voluptatem ut quis voluptatem. Praesentium facilis eligendi
              quis.
            </div>
          </template>
        </KsExpandItem>
        <KsExpandItem title="Four">
          <template #content>
            <div>
              Three At nam quia voluptatem quia ut. Vero laudantium provident
              dolor voluptatem ut quis voluptatem. Praesentium facilis eligendi
              quis.
            </div>
          </template>
        </KsExpandItem>
      </KsExpand>
      <div
        v-if="options.demo === 'Custom toggle'"
        class="docs-custom-expand"
      >
        <KsExpand
          :key="`${options.size}`"
          :size="options.size"
          :multiple="options.multiple"
        >
          <KsExpandItem
            title="solution"
            shape="rounded"
          >
            <template #prefix="{ open }">
              {{ open ? 'Close' : 'Open' }}
            </template>
            <template #toggle>Question 1</template>
            <template #content>
              <div>
                Solution 1 at nam quia voluptatem quia ut. Vero laudantium
                provident dolor voluptatem ut quis voluptatem. Praesentium
                facilis eligendi quis.
              </div>
            </template>
          </KsExpandItem>
          <KsExpandItem
            title="solution"
            shape="rounded"
          >
            <template #prefix="{ open }">
              {{ open ? 'Close' : 'Open' }}
            </template>
            <template #toggle>Question 2</template>
            <template #content>
              <div>
                Solution 2 at nam quia voluptatem quia ut. Vero laudantium
                provident dolor voluptatem ut quis voluptatem. Praesentium
                facilis eligendi quis.
              </div>
            </template>
          </KsExpandItem>
          <KsExpandItem
            title="solution"
            shape="rounded"
          >
            <template #prefix="{ open }">
              {{ open ? 'Close' : 'Open' }}
            </template>
            <template #toggle>Question 3</template>
            <template #content>
              <div>
                Solution 3 at nam quia voluptatem quia ut. Vero laudantium
                provident dolor voluptatem ut quis voluptatem. Praesentium
                facilis eligendi quis.
              </div>
            </template>
          </KsExpandItem>
        </KsExpand>
      </div>
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Demo</legend>
          <select v-model="options.demo">
            <option
              v-for="demoOption in options.demos"
              :key="demoOption"
              :value="demoOption"
              v-text="demoOption"
            />
          </select>
        </fieldset>
        <label>
          Multiple:
          <input
            v-model="options.multiple"
            type="checkbox"
          />
        </label>
        <br />
        <fieldset>
          <legend>Size</legend>
          <label>
            Small
            <input
              v-model="options.size"
              class="ks-input"
              name="size"
              type="radio"
              value="small"
            />
          </label>
          <label>
            Medium
            <input
              v-model="options.size"
              class="ks-input"
              name="size"
              type="radio"
              value="medium"
            />
          </label>
          <label>
            Large
            <input
              v-model="options.size"
              class="ks-input"
              name="size"
              type="radio"
              value="large"
            />
          </label>
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>
