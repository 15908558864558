<script setup lang="ts">
  import { ref, computed, nextTick } from 'vue'
  import { uid } from './utils'
  import { KsInput, KsIcon, KsButton } from '.'
  import {
    faChevronDown,
    faXmark,
    faCheck
  } from '@fortawesome/pro-regular-svg-icons'

  const props = withDefaults(
    defineProps<{
      modelValue: any
      options: any[]
      placeholder: string
      noOptions?: string
      multiple?: boolean
      flexible?: boolean
      resettable?: boolean
      searchable?: boolean
      closeOnSelect?: boolean
      optionLabel?: (option: any) => string
      searchKey?: (option: any) => string
      reduce?: (option: any) => any
      selectable?: (option: any) => boolean
      deselectable?: (option: any) => boolean
      optionId?: (option: any) => string
      shape?: string
      autolabel?: boolean
    }>(),
    {
      noOptions: 'Ingen resultater',
      multiple: false,
      flexible: false,
      resettable: false,
      searchable: false,
      closeOnSelect: true,
      optionLabel: JSON.stringify,
      searchKey: undefined,
      reduce: (option: any) => option,
      selectable: () => true,
      deselectable: () => true,
      optionId: JSON.stringify,
      shape: 'normal',
      autolabel: false
    }
  )

  const emit = defineEmits<{
    (event: 'open'): void
    (event: 'close'): void
    (event: 'search', filterValue: string): void
    (event: 'select', option: any): void
    (event: 'reset'): void
    (event: 'update:modelValue', selection: any): void
  }>()

  const open = ref(false)
  const filterValue = ref('')
  const selecting = ref(false)
  const dropdown = ref()
  const list = ref()
  const input = ref()
  const uuid = uid()

  const displayValue = computed(() => {
    // Used as input placeholder
    return (
      props.options.filter(isSelected).map(props.optionLabel).join(', ') ||
      props.placeholder
    )
  })

  const filteredOptions = computed(() => {
    if (!props.searchable || filterValue.value === '') return props.options
    return props.options.filter((option) =>
      searchKey(option).includes(lowerCaseFilterValue.value)
    )
  })

  const inputSize = computed(() => {
    const size = displayValue.value.length
    return props.flexible ? size : -1
  })

  const lowerCaseFilterValue = computed(() => {
    return filterValue.value.toLocaleLowerCase()
  })

  const hasSelection = computed(() => {
    return displayValue.value !== props.placeholder
  })

  const hasOptions = computed(() => {
    return filteredOptions.value.length > 0
  })

  const showReset = computed(() => {
    return props.resettable && hasSelection.value && hasOptions.value
  })

  function searchKey(option: any) {
    return (
      props.searchKey ? props.searchKey(option) : props.optionLabel(option)
    ).toLocaleLowerCase()
  }

  function equals(option1: any, option2: any) {
    if (!option1 || !option2) return false
    return props.optionId(option1) === props.optionId(option2)
  }

  function isSelected(option: any) {
    const value = props.modelValue
    const optionReduced = props.reduce(option)

    if (Array.isArray(value)) {
      return value.some((opt) => equals(opt, optionReduced))
    } else {
      return equals(value, optionReduced)
    }
  }

  async function onSelect(option: any) {
    let value = props.modelValue // Value/modelValue
    let selection = null
    const optionReduced = props.reduce(option) // Reduced for comparison;

    if (!props.selectable(option)) return

    if (props.multiple) {
      if (!Array.isArray(value)) value = [] // Only happens if props.multiple changes
      if (
        value.some((opt: any) => equals(opt, optionReduced)) &&
        props.deselectable(option)
      ) {
        selection = value.filter((opt: any) => !equals(opt, optionReduced)) // Remove option from selection
      } else {
        selection = value.concat(optionReduced) // Add option to selection
      }
    } else if (equals(value, optionReduced) && props.deselectable(option)) {
      selection = null
    } else {
      selection = optionReduced
    }

    await nextTick() // Update v-model value after internal update
    emit('update:modelValue', selection)
    emit('select', option)

    selecting.value = true
    if (props.closeOnSelect) {
      filterValue.value = ''
      input.value.el.focus()
    }
  }

  function onOpen() {
    open.value = true
    emit('open')
  }

  function onClose() {
    open.value = false
    emit('close')
  }

  function onToggle() {
    if (open.value) onClose()
    else onOpen()
  }

  function onInput() {
    onOpen()
    emit('search', filterValue.value)
  }

  function onReset() {
    emit('update:modelValue', props.multiple ? [] : null)
    emit('reset')
  }

  function onEscape() {
    onClose()
    input.value.el.focus()
  }

  function onUpDown(event: KeyboardEvent) {
    const key = event.key
    const target = event.target as HTMLElement
    const isUp = key === 'ArrowUp'
    let focusTarget

    if (target === input.value.el) {
      focusTarget = isUp
        ? list.value.lastElementChild
        : list.value.firstElementChild
    } else {
      focusTarget = isUp
        ? target.previousElementSibling
        : target.nextElementSibling
    }
    open.value = true
    focusTarget = focusTarget || input.value.el
    focusTarget.focus()
  }

  function onFocusin(event: FocusEvent) {
    if (
      event.target === input.value.el &&
      !list.value.contains(event.relatedTarget)
    ) {
      onOpen() // Focus on input – not from list – open
    } else if (selecting.value && props.closeOnSelect) {
      // Close on selection, ignore navigation
      selecting.value = false
      onClose()
    }
  }

  function onFocusout({ relatedTarget }: FocusEvent) {
    if (
      list.value?.contains(relatedTarget) ||
      relatedTarget === input.value?.el
    )
      return // Focus was list or input – skip
    onClose()
  }

  defineExpose({ el: dropdown })
</script>

<template>
  <div
    ref="dropdown"
    class="ks-dropdown"
    :class="{
      'ks-dropdown-open': open,
      'ks-dropdown-resettable': showReset,
      'ks-dropdown-searchable': searchable
    }"
    @focusin="onFocusin"
    @focusout="onFocusout"
    @keydown.esc.prevent="onEscape"
    @keydown.up.down.prevent="onUpDown"
  >
    <span
      v-if="autolabel"
      :id="`ks-dropdown-label-${uuid}`"
      class="ks-dropdown-label ks-sr-only"
      aria-hidden="true"
      v-text="autolabel ? placeholder : ''"
    />
    <KsInput
      ref="input"
      v-model="filterValue"
      class="ks-dropdown-input ks-truncate"
      :size="inputSize"
      :shape="shape"
      :type="searchable ? 'search' : 'text'"
      role="combobox"
      autocomplete="off"
      aria-autocomplete="list"
      :aria-controls="`ks-dropdown-${uuid}`"
      :aria-expanded="String(open)"
      :aria-labelledby="autolabel ? `ks-dropdown-label-${uuid}` : ''"
      :placeholder="displayValue"
      :readonly="!searchable"
      @input="onInput"
      @mousedown="onToggle"
      @keydown.enter.self="onOpen"
    />
    <KsIcon
      :icon="faChevronDown"
      class="ks-dropdown-chevron"
    />
    <KsButton
      v-if="showReset"
      class="ks-dropdown-reset"
      shape="round"
      size="small"
      :icon-right="faXmark"
      aria-label="Nullstill valg"
      title="Nullstill valg"
      @click="onReset"
    />
    <div class="ks-dropdown-list">
      <ul
        :id="`ks-dropdown-${uuid}`"
        ref="list"
        role="listbox"
        :hidden="!open"
        tabindex="-1"
      >
        <li
          v-for="(option, index) of filteredOptions"
          :key="option"
          role="option"
          :aria-posinset="index + 1"
          :aria-setsize="filteredOptions.length"
          :aria-selected="isSelected(option)"
          :aria-disabled="!props.selectable(option)"
          tabindex="-1"
          @click="onSelect(option)"
          @keydown.enter.space.prevent="onSelect(option)"
        >
          {{ optionLabel(option) }}
          <KsIcon
            v-if="isSelected(option)"
            :icon="faCheck"
            class="ks-dropdown-check"
          />
        </li>
        <li
          v-if="!hasOptions"
          v-text="noOptions"
        />
      </ul>
    </div>
  </div>
</template>

<style scoped>
  .ks-dropdown {
    display: inline-block;
    position: relative;
    max-width: 100%;
    font-size: 1rem;
  }

  .ks-dropdown-input {
    color: var(--ks-secondary);
    font-weight: 500;
    cursor: pointer;
    padding-right: 2.8em !important;
  }

  .ks-dropdown-input::placeholder {
    opacity: 1;
    color: var(--ks-secondary);
    font-weight: 500;
  }

  .ks-dropdown-input:focus-visible {
    background: var(--ks-input) !important;
  }

  .ks-dropdown-input:read-only {
    cursor: pointer;
  }

  .ks-dropdown-chevron {
    position: absolute;
    top: 0.75em;
    right: 1em;
    color: var(--ks-secondary);
    transition: 0.2s;
  }

  .ks-dropdown-reset {
    position: absolute;
    font-size: 0.6em;
    top: 0.9em;
    right: 4.2em;
  }

  .ks-dropdown-list {
    position: absolute;
    top: 100%;
    min-width: 100%;
    max-width: calc(100vh - 2rem);
    z-index: 50;
  }

  .ks-dropdown-list ul {
    list-style: none;
    margin: 0.5em 0 0;
    padding: 0.5em;
    max-height: 235px;
    font-size: 1rem;
    border-radius: calc(12px * var(--ks-roundness));
    background: var(--ks-surface);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overscroll-behavior: contain;
    transition: opacity 0.2s;
  }

  .ks-dropdown-list ul[hidden] {
    display: block;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    padding: 0;
    height: 0;
  }

  .ks-dropdown-list li {
    position: relative;
    padding: 0.7em 0.8em;
    margin: 0.3em 0;
    color: var(--ks-secondary);
    border-radius: calc(12px * var(--ks-roundness));
    line-height: 1;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
  }

  .ks-dropdown-list li[aria-selected='true'] {
    padding-right: 4em;
  }

  .ks-dropdown-list li[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .ks-dropdown-list li[aria-disabled='false']:hover {
    background: var(--ks-dropdownhover);
  }

  .ks-dropdown-list li[aria-disabled='false']:focus-visible {
    box-shadow: 0 0 0 3px var(--ks-focusring);
  }

  .ks-dropdown-check {
    position: absolute;
    top: 0.6em;
    right: 0.8em;
  }

  .ks-dropdown-resettable .ks-dropdown-input {
    padding-right: 4em !important;
  }

  .ks-dropdown-searchable .ks-dropdown-input:focus-visible {
    background: var(--ks-inputfocus) !important;
  }

  .ks-dropdown-open .ks-dropdown-chevron {
    transform: rotate(180deg);
  }
</style>
