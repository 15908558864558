<script setup lang="ts">
  import { ref, reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsInput, KsTextArea, KsButton, KsSwitch } from '../../lib/js'

  const options = reactive({
    type: 'text',
    disabled: false,
    indeterminate: false,
    shape: 'normal'
  })
  const inputValue = ref('Apple')
  const input = ref()

  function setIndeterminate({ target }: Event) {
    const checkbox = target as HTMLInputElement
    input.value.el.indeterminate = checkbox.checked
  }
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <KsInput
        v-if="
          options.type.match(
            /^(text|search|number|time|password|checkbox|radio|range)$/g
          )
        "
        ref="input"
        v-model="inputValue"
        :type="options.type"
        placeholder="Type something..."
        :shape="options.shape"
        :disabled="options.disabled"
      />
      <KsInput
        v-if="options.type === 'file'"
        type="file"
        :disabled="options.disabled"
      />
      <KsButton
        v-if="options.type === 'checkbox-button'"
        variant="secondary"
        type="label"
        :shape="options.shape"
        :disabled="options.disabled"
      >
        <KsInput
          v-model="inputValue"
          type="checkbox"
          :disabled="options.disabled"
        />
        Checkbox
      </KsButton>
      <KsButton
        v-if="options.type === 'radio-button'"
        variant="secondary"
        type="label"
        :shape="options.shape"
        :disabled="options.disabled"
      >
        <KsInput
          v-model="inputValue"
          type="radio"
          :disabled="options.disabled"
        />
        Radio
      </KsButton>
      <KsSwitch
        v-if="options.type === 'switch'"
        v-model="inputValue"
        :disabled="options.disabled"
      />
      <KsTextArea
        v-if="options.type === 'textarea'"
        rows="3"
        placeholder="Type something..."
        :disabled="options.disabled"
      />
    </template>
    <template #controls>
      <div>
        <fieldset>
          <legend>Type</legend>
          <label>
            Text
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="text"
            />
          </label>
          <label>
            Search
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="search"
            />
          </label>
          <label>
            Password
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="password"
            />
          </label>
          <label>
            Number
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="number"
            />
          </label>
          <label>
            Time
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="time"
            />
          </label>
          <label>
            File
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="file"
            />
          </label>
          <label>
            Range
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="range"
            />
          </label>
          <label>
            Checkbox
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="checkbox"
            />
          </label>
          <label>
            Radio
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="radio"
            />
          </label>
          <label>
            Switch
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="switch"
            />
          </label>
          <label>
            Checkbox button
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="checkbox-button"
            />
          </label>
          <label>
            Radio button
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="radio-button"
            />
          </label>
          <label>
            Textarea
            <input
              v-model="options.type"
              class="ks-input"
              name="type"
              type="radio"
              value="textarea"
            />
          </label>
        </fieldset>
      </div>
      <div>
        <fieldset>
          <legend>State</legend>
          <label>
            Disabled
            <input
              v-model="options.disabled"
              class="ks-input"
              type="checkbox"
            />
          </label>
          <label v-if="options.type === 'checkbox'">
            Indeterminate
            <input
              class="ks-input"
              type="checkbox"
              @change="setIndeterminate"
            />
          </label>
        </fieldset>
        <fieldset>
          <legend>Shape</legend>
          <label>
            Normal
            <input
              v-model="options.shape"
              class="ks-input"
              name="shape"
              type="radio"
              value="normal"
            />
          </label>
          <label>
            Rounded
            <input
              v-model="options.shape"
              class="ks-input"
              name="shape"
              type="radio"
              value="rounded"
            />
          </label>
        </fieldset>
      </div>
    </template>
  </KsDocsComponent>
</template>
