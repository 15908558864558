<script setup lang="ts">
  import { computed, nextTick, ref, watch } from 'vue'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
  import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'

  type IconVariant = 'regular' | 'solid' | 'light' | 'sharp-regular'

  const props = withDefaults(
    defineProps<
      {
        icon: string | IconDefinition
        scale?: number
        /**
         * @deprecated Use an {@link IconDefinition} in the `icon` prop instead
         */
        variant?: IconVariant
        animationDuration?: string
        loopAnimation?: boolean
        bounce?: boolean
        shake?: boolean
        beat?: boolean
        fade?: boolean
        spin?: boolean
        beatFade?: boolean
        spinPulse?: boolean
        spinReverse?: boolean
      } & (
        | {
            icon: string
            variant?: IconVariant
          }
        | {
            icon: IconDefinition
            variant?: never
          }
      )
    >(),
    {
      scale: 1.0,
      variant: 'regular',
      animationDuration: '1000ms',
      loopAnimation: false,
      bounce: false,
      shake: false,
      beat: false,
      fade: false,
      spin: false,
      beatFade: false,
      spinPulse: false,
      spinReverse: false
    }
  )

  const getIcon: Record<IconVariant, (icon: string) => string> = {
    regular: (icon) => `fa-regular fa-${icon}`,
    solid: (icon) => `fa-solid fa-${icon}`,
    light: (icon) => `fa-light fa-${icon}`,
    'sharp-regular': (icon) => `fa-sharp fa-regular fa-${icon}`
  }

  const iconRef = ref()
  const renderIcon = ref(true)

  const size = computed(() => `${1.25 * props.scale}em`)
  const iconToRender = computed(() => {
    if (typeof props.icon === 'string') {
      return getIcon[props.variant](props.icon)
    }

    return props.icon
  })
  const animationIterationCount = computed(() =>
    props.loopAnimation ? 'infinite' : '1'
  )

  // watch for prop changes to re-render icon with new styles dynamically
  watch(props, async () => {
    renderIcon.value = false
    await nextTick()
    renderIcon.value = true
  })

  defineExpose({ el: iconRef })
</script>

<template>
  <FontAwesomeIcon
    v-if="renderIcon"
    ref="iconRef"
    :style="{
      width: size,
      height: size,
      'pointer-events': 'none',
      '--fa-animation-duration': props.animationDuration,
      '--fa-animation-iteration-count': animationIterationCount
    }"
    :icon="iconToRender"
    :bounce="bounce"
    :shake="shake"
    :beat="beat"
    :fade="fade"
    :spin="spin"
    :beat-fade="beatFade"
    :spin-pulse="spinPulse"
    :spin-reverse="spinReverse"
  />
</template>
