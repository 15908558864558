<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsBreadcrumbs } from '../../lib/js'

  const options = reactive({
    items: [
      { title: 'Home', href: '#' },
      { title: 'Books', href: '#' },
      { title: 'Crime and Punishment', href: '#' },
      { title: 'About', href: '#' }
    ]
  })
</script>

<template>
  <KsDocsComponent fullwidth>
    <template #viewer>
      <div>
        <KsBreadcrumbs :items="options.items" />
      </div>
    </template>
  </KsDocsComponent>
</template>
