<script setup lang="ts">
  import { reactive } from 'vue'
  import KsDocsComponent from './KsDocsComponent.vue'
  import { KsDropdown } from '../../lib/js'

  const options = reactive({
    multiple: true,
    options: [
      { name: 'Apple', value: 'apple', order: 1 },
      { name: 'Pear', value: 'pear', order: 2 },
      { name: 'Banana', value: 'banana', order: 3 },
      { name: 'Cranberry', value: 'cranberry', order: 4 }
    ],
    value: [
      { name: 'Pear', value: 'pear', order: 2 },
      { name: 'Banana', value: 'banana', order: 3 }
    ],
    searchable: false,
    deselectable: true,
    flexible: false,
    resettable: false,
    closeOnSelect: true,
    shape: 'normal',
    autolabel: true
  })
</script>

<template>
  <KsDocsComponent centered>
    <template #viewer>
      <div>
        <KsDropdown
          v-model="options.value"
          :options="options.options"
          placeholder="Pick a fruit..."
          :shape="options.shape"
          :multiple="options.multiple"
          :flexible="options.flexible"
          :resettable="options.resettable"
          :searchable="options.searchable"
          :close-on-select="options.closeOnSelect"
          :option-label="({ name }) => name"
          :option-id="({ value }) => value"
          :search-key="({ value, order }) => `${value}${order}`"
          :autolabel="options.autolabel"
        />
      </div>
    </template>
    <template #controls>
      <div>
        <label>
          Searchable
          <input
            v-model="options.searchable"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Multiple
          <input
            v-model="options.multiple"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Flexible
          <input
            v-model="options.flexible"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Resettable
          <input
            v-model="options.resettable"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Close on select
          <input
            v-model="options.closeOnSelect"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <label>
          Auto label
          <input
            v-model="options.autolabel"
            class="ks-input"
            type="checkbox"
          />
        </label>
        <br />
        <fieldset>
          <legend>Shape</legend>
          <label>
            Normal
            <input
              v-model="options.shape"
              class="ks-input"
              name="shape"
              type="radio"
              value="normal"
            />
          </label>
          <label>
            Rounded
            <input
              v-model="options.shape"
              class="ks-input"
              name="shape"
              type="radio"
              value="rounded"
            />
          </label>
        </fieldset>
        <br />
        Selection: {{ [options.value].flat().map((opt) => opt?.name) }}
      </div>
    </template>
  </KsDocsComponent>
</template>
