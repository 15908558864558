<script lang="ts" setup>
  import { ref } from 'vue'

  const textarea = ref()

  defineExpose({ el: textarea })
</script>

<template>
  <textarea
    ref="textarea"
    class="ks-textarea"
  />
</template>

<style scoped>
  .ks-textarea {
    appearance: none;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: top;
    overflow: auto;
    resize: vertical;
    border-radius: calc(16px * var(--ks-roundness));
    color: var(--ks-text);
    background: var(--ks-input);
    background-repeat: no-repeat;
    border: 0;
    font: inherit;
    font-size: 1rem;
    margin: 0;
    padding: 0.6em 1.2em;
    transition: 0.2s;
  }

  .ks-textarea:not(:disabled):hover {
    background: var(--ks-inputhover);
  }

  .ks-textarea:disabled {
    cursor: not-allowed;
    color: var(--ks-text);
    opacity: 0.7;
  }

  .ks-textarea:not(:disabled):focus-visible {
    box-shadow: 0 0 0 3px var(--ks-focusring);
    background: var(--ks-inputfocus);
  }
</style>
